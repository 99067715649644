import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "about-patterns"
    }}>{`About patterns`}</h2>
    <p>{`In Carbon, patterns comprise common user flows and scenarios which are too complex or too universal to be encapsulated in a single component. Individual patterns often include two or more components from the system or apply to multiple components independently.`}</p>
    <p>{`The patterns library is constantly growing, and the Carbon team encourages contributions of UX patterns which can apply to a broad set of Carbon users.`}</p>
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Production patterns`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/patterns/common-actions"
            }}>{`Common actions`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These are the frequently used actions that appear multiple times across different components and workflows.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/patterns/disabled-states"
            }}>{`Disabled states`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Are used to completely remove the interactive function of a component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/patterns/filtering"
            }}>{`Filtering`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is the mechanism by which a user adds or removes data items from a displayed data set.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/patterns/loading"
            }}>{`Loading`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Loading patterns are used when information takes an extended amount of time to process and appear on screen.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/patterns/overflow-content"
            }}>{`Overflow content`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is text, such as a paragraph or a text string, that exceeds a desired space.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      